import { Injectable } from '@angular/core';
import { Router } from  "@angular/router";
import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, observable, Subject, BehaviorSubject } from 'rxjs';
import { User } from '../../../models/user';
import * as firebase from 'firebase';
import { StorageService } from "../../storage/storage.service";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store';
import { updateCurrentUser } from 'src/app/shared/store/user/user.action';

@Injectable()
export class AuthService {

  userData: any; // Save logged in user data
  private userDetailsObservale = new Subject<User>();
  
  private isLoggedIn$: BehaviorSubject<boolean>;

  // private userDetails:Observable<any>;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth:  AngularFireAuth, 
    public router:  Router,
    private storageService: StorageService,
    private store: Store<AppState>,
  ) { 

    this.isLoggedIn$ = new BehaviorSubject<boolean>(this.localStorageSessionCheck());


    this.afAuth.authState.subscribe(user => {
      if (user){

        this.isLoggedIn$.next(true);

        // this.store.dispatch(updateCurrentUser({user: user}));

        this.userData = user;
        
        // if (this.getUserType() && this.userData.displayName) {
          
        //   localStorage.setItem('user', JSON.stringify(this.userData));
        //   JSON.parse(localStorage.getItem('user')); // DOubtfull line

        //   // Load user based things
        // } else {

        // debugger
          this.afs.doc(`users/${this.userData.uid}`).ref.get().then((doc) => {
            if (doc.exists) {
              // this.userData.setItem('')
              // console.log("=============+>", doc.data(), this.userData.user.displayName);
              let user_data = doc.data();

              // debugger
              this.store.dispatch(updateCurrentUser({user: user_data}));

              user.updateProfile({
                displayName: user_data.displayName,
                photoURL: '/assets/img/user-avatar.png',
              })
              
              console.log("=================>", this.userData);

              this.setUserType(user_data.type);

              localStorage.setItem('user', JSON.stringify(this.userData));
              JSON.parse(localStorage.getItem('user')); // DOubtfull line
            } else {
              console.log("Unable to fetch user data!");
            }
          }).catch(function(error) {
            console.log("Error getting user document:", error);
          });

        // }
        
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user')); // DOubtfull line
      }
    });
  }

  private getFirebaseUserSession(): any {
    return JSON.parse(this.storageService.get('user'));
  }



  getAuthorizationToken(): string {
    // return this.getFirebaseUserSession()?.stsTokenManager?.accessToken;
    return localStorage.getItem('accessToken');
  }

  // setAuthorizationToken(value: string) {
  //   this.storageService.set('accessToken', value);
  //   this.isLoggedIn$.next(this.isToken());
  // }

  // setRefreshToken(value: string) {
  //   this.storageService.set('refreshToken', value);
  // }

  // removeAuthorizationToken(): void {
  //   this.storageService.removeItem('accessToken');
  //   this.isLoggedIn$.next(this.isToken());
  // }

  isLoggedIn(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  // setUsername(value: string) {
  //   this.storageService.set('username', value);
  // }

  // getUsername(): string {
  //   return this.storageService.get('username');
  // }

  setUserType(value: number) {
    this.storageService.set('userType', value);
  }

  getUserType() {
    return parseInt(this.storageService.get('userType'));
  }

  getCurrentUser() {
    return JSON.parse(this.storageService.get('user'));
  }

  setCurrentUser(value: string) {
    this.storageService.set('user', value);
  }

  // deleteUserData() {
  //   this.storageService.removeItem('refreshToken');
  //   this.storageService.removeItem('username');
  //   this.storageService.removeItem('userType');
  //   this.storageService.removeItem('status');
  //   this.removeAuthorizationToken();
  // }

  // private isToken(): boolean {
  //   const token = this.getAuthorizationToken();
  //   return token !== null && token !== undefined && token !== '';
  // }

  private localStorageSessionCheck(): boolean {
    const user = this.getCurrentUser();
    const userType = this.getUserType();
    return user != null && userType != NaN;
  }

  // async login(email: string, password: string, callback) {
  //     await this.afAuth.auth.signInWithEmailAndPassword(email, password)
  //     .then((result) => {

        
  //       this.userDetailsObservale.next(result.user);
  //       callback({"status": "success", "message": ""});
  //     }).catch((error) => {
  //       // window.alert(error)
  //       callback({"status": "failure", "message": error});
  //     });
  //     // this.router.navigate(['admin/list']);
  // }


  // async register(displayName: string, email: string, password: string, callback) {
  //     await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
  //     .then((result) => {

  //       const userData: User = {
  //         uid: result.user.uid,
  //         email: result.user.email,
  //         displayName: displayName,
  //         photoURL: "/assets/img/user-avatar.png",
  //         emailVerified: result.user.emailVerified
  //       }
  //       this.SetUserData(userData, true);
          
  //       // console.log(result.user.displayName);
  //       this.userDetailsObservale.next(result.user);

  //       callback({"status": "success", "message": ""});
      
  //     }).catch((error) => {
  //       callback({"status": "failure", "message": error});
  //     });

  //     this.sendEmailVerification();
  // }

  // async sendEmailVerification() {
  //     await this.afAuth.auth.currentUser.sendEmailVerification()
  //     // this.router.navigate(['admin/verify-email']);
  // }

  // async sendPasswordResetEmail(passwordResetEmail: string) {
  //     return await this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
  // }

  async logout(){
      await this.afAuth.signOut();
      localStorage.removeItem('user');
      localStorage.removeItem('userType');
      localStorage.removeItem('accessToken');
      window.location.reload();
  }



  // /*********************************************** */
  // get isLoggedIn(): boolean {
  //     const  user  =  JSON.parse(localStorage.getItem('user'));
  //     return  user  !==  null;
  // }

  // async  loginWithGoogle(){
  //     await  this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
  //     this.router.navigate(['admin/list']);
  // }

  // // Sign in with Google
  // GoogleAuth() {
  //     return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // // Sign in with Google
  // FacebookAuth() {
  //     return this.AuthLogin(new auth.FacebookAuthProvider());
  // }

  //   // Auth logic to run auth providers
  // AuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider)
  //   .then((result) => {
  //     console.log(result.user)
  //     //  this.ngZone.run(() => {
  //     //     this.router.navigate(['dashboard']);
  //     //   })
  //     this.SetUserData(result.user, false);

  //     console.log(result.user.displayName);
  //     this.userDetailsObservale.next(result.user);
    
  //   }).catch((error) => {
  //     // window.alert(error)
  //     throw error;
  //   })
  // }

  // /* Setting up user data when sign in with username/password, 
  // sign up with username/password and sign in with social auth  
  // provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user, set_default_avatar: boolean) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: set_default_avatar ? "/assets/img/user-avatar.png" : user.photoURL,
  //     emailVerified: user.emailVerified
  //   }
  //   return userRef.set(userData, {
  //     merge: true
  //   })
  // }

  // get getUserData():User {
  //   let user:User = JSON.parse(localStorage.getItem('user'));
  //   return user;
  // }



  // getUserDetailsObservale():any {
  //     return this.userDetailsObservale;
  // }
}