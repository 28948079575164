import { Injectable } from '@angular/core';
import * as sha1 from 'js-sha1';
import { NetworkService } from '../network/network.service';
import { Api } from '../../constants/api';
import { HttpParams } from '@angular/common/http';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { map } from 'rxjs/operators';

@Injectable()
export class BbbService {
  

  private config = {
    allowStartStopRecording: false,
    attendeePW: 'ap',
    moderatorPW: 'mp',
    autoStartRecording: false,
    meetingID: null,
    name: null,
    record: false,
    voiceBridge: "78205",
    welcome: "Welcome to Xarothi's Classroom",
    redirect: true,
  }

  private secret: string = 'l26b44va8AD1YvG9nt6PnE0dY618ASDxdJeBqwVOI';

  constructor(
    private networkService: NetworkService,
    private ngxXml2jsonService: NgxXml2jsonService
  ) { 
    
  }

  public getStatus$() {
    return this.networkService.get<any>(Api.CLASSROOM_STATUS, Api.CLASSROOM_BASE_URL);
  }

  public create$(meetingID: string, name: string) {
    let queryParams = new HttpParams()
      .set('allowStartStopRecording', this.config.allowStartStopRecording.toString())
      .set('attendeePW', this.config.attendeePW)
      .set('autoStartRecording', this.config.autoStartRecording.toString())
      .set('meetingID', meetingID)
      .set('moderatorPW', this.config.moderatorPW)
      .set('name', name)
      .set('record', this.config.record.toString())
      .set('voiceBridge', this.config.voiceBridge);

    let checksum = this.generateChecksum(queryParams.toString(), "create");
    queryParams = queryParams.set("checksum", checksum);


    return this.networkService.get<any>(Api.CLASSROOM_CREATE(queryParams.toString()), Api.CLASSROOM_BASE_URL, 'text')
                .pipe(
                  map(res => {
                    const parser = new DOMParser();
                    const xml = parser.parseFromString(res, 'text/xml');
                    return this.ngxXml2jsonService.xmlToJson(xml);
                  })
                );
  }

  public joinMeetingAsModeratorURL(meetingID: string, name: string) {
    let queryParams = new HttpParams()
      .set('fullName', name)
      .set('meetingID', meetingID)
      .set('password', this.config.moderatorPW)
      .set('redirect', this.config.redirect.toString());

    let checksum = this.generateChecksum(queryParams.toString(), "join");
    queryParams = queryParams.set("checksum", checksum);

    return Api.CLASSROOM_BASE_URL + Api.CLASSROOM_JOIN(queryParams);

  }

  public joinMeetingAsAttendeeURL(meetingID: string, name: string) {
    let queryParams = new HttpParams()
      .set('fullName', name)
      .set('meetingID', meetingID)
      .set('password', this.config.attendeePW)
      .set('redirect', this.config.redirect.toString());
      
    let checksum = this.generateChecksum(queryParams.toString(), "join");
    queryParams = queryParams.set("checksum", checksum);

    return Api.CLASSROOM_BASE_URL + Api.CLASSROOM_JOIN(queryParams);

  }

  public isMeetingRunning(meetingID: string) {
    let queryParams = new HttpParams()
      .set('meetingID', meetingID);
      
    let checksum = this.generateChecksum(queryParams.toString(), "isMeetingRunning");
    queryParams = queryParams.set("checksum", checksum);

    return this.networkService.get<any>(Api.CLASSROOM_MEETING_STATUS(queryParams.toString()), Api.CLASSROOM_BASE_URL, 'text')
                .pipe(
                  map(res => {
                    const parser = new DOMParser();
                    const xml = parser.parseFromString(res, 'text/xml');
                    return this.ngxXml2jsonService.xmlToJson(xml);
                  })
                );

  }
    
  public generateChecksum(str: string, apiCallName: string) {
    return sha1(apiCallName + str + this.secret);
  }
}
