export class BaseResponse<T> {
    private status: string;
    private data: T;
    private code: number;
    private message: string;
    private success: boolean;
    private responseTime: string;

    constructor(
        status?: string,
        data?: T,
        code?: number,
        message?: string,
        success?: boolean,
        responseTime?: string
    ) {
        this.status = status;
        this.data = data;
        this.code = code;
        this.message = message;
        this.success = success;
        this.responseTime = responseTime;
    }

    public getInterfaceSkeleton() {
        return {
            status: this.status,
            data: this.data,
            code: this.code,
            message: this.message,
            success: this.success,
            responseTime: this.responseTime
        };
    }
}
