import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../../models/user';

@Injectable()
export class UserService {

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public  afAuth:  AngularFireAuth, 
  ) { }

  createUser(user: User, set_default_avatar: boolean) {
    const userCollection = this.afs.collection(`users`);
    const userData: User = {
      uid: user.uid,
      mobile: user.mobile,
      name: user.name,
      type: user.type,
      gender: user.gender,
      photoURL: set_default_avatar ? "/assets/img/user-avatar.png" : user.photoURL,
    }
    return userCollection.doc(userData.uid).set(userData);
  }

  getUserByMobileNumber(mobile: number, callback) {
    const userCollection = this.afs.collection(`users`, ref => ref.where('mobile', '==', mobile));
    
    userCollection.valueChanges().subscribe(res => {
      callback(res)
    });

  }

  updateUser(user: User, set_default_avatar: boolean) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      mobile: user.mobile,
      name: user.name,
      type: user.type,
      gender: user.gender,
      photoURL: set_default_avatar ? "/assets/img/user-avatar.png" : user.photoURL,
    }
    return userRef.set(userData, { merge: true });
  }

  public getUserByUserIds$(user_ids: string[]) {
    const usersCollection: AngularFirestoreCollection<User> = this.afs.collection(`users`, ref => ref.where('uid', 'in', user_ids));
    return usersCollection.valueChanges();
  }
}
