import { UserType } from '../enums/user-type.enum';

export function dashboardStudent(url) {
        
    let url_match = url.length > 0 && url[0].path.match('dashboard');
    let user_type_match = parseInt(localStorage.getItem('userType')) == UserType.STUDENT;

    if (url_match && user_type_match) {
    // if (user_type_match) {
        return  { consumed: [url[0]] };
    }
    return null;
}

export function dashboardTeacher(url) {
        
    let url_match = url.length > 0 && url[0].path.match('dashboard');
    let user_type_match = parseInt(localStorage.getItem('userType')) == UserType.TEACHER;

    if (url_match && user_type_match) {
    // if (user_type_match) {
        return  { consumed: [url[0]] };
    }
    return null;
}