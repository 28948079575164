import { Injectable } from '@angular/core';
import { Standard } from '../../models/standard';
import { Subject } from '../../models/subject';
import { of } from 'rxjs';

@Injectable()
export class MasterDataService {

  constructor() { }

  public getMdStandards$() {
    const standards: Standard[] = [
      {
        name: "Class 8",
        value: 8
      },
      {
        name: "Class 9",
        value: 9
      },
      {
        name: "Class 10",
        value: 10
      }
    ];

    return of(standards);
  }

  public getMdSubjects$() {
    const subjects: Subject[] = [
      {
        name: "Mathematics",
      },
      {
        name: "General Science",
      },
      {
        name: "Social Science",
      }
    ];

    return of(subjects);
  }

}
