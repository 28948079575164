export class Api {
    // public static BASE_URL = "http://localhost/";
    public static BASE_URL = "https://api.xarothi.in/";

    public static CLASSROOM_BASE_URL = "https://classroom.xarothi.in/bigbluebutton/api"
    public static CLASSROOM_STATUS = "/";
    public static CLASSROOM_CREATE = (queryParams) =>  `/create?${queryParams}`;
    public static CLASSROOM_JOIN = (queryParams) => `/join?${queryParams}`;
    public static CLASSROOM_MEETING_STATUS = (queryParams) => `/isMeetingRunning?${queryParams}`;
    public static CLASSROOM_MEETING_INTO ="/getMeetingInfo";
    public static CLASSROOM_MEETING_END = "/end";
    public static CLASSROOM_MEETING_GET_ALL = "/getMeetings";

    // ========================================================================================

    // User service
    public static TEACHER_FIND_STUDENT = (mobile) => `user/teacher/findStudent/${mobile}`;
    public static TEACHER_ADD_TO_MY_LIST = (user_id) => `user/teacher/addStudentToKnownList/${user_id}`;
    public static TEACHER_REMOVE_FROM_MY_LIST = (user_id) => `user/teacher/removeStudentFromKnownList/${user_id}`;
    public static TEACHER_FETCH_MY_STUDENTS = `user/teacher/fetchMyStudents`;

    public static BATCH_CREATE = 'user/batch/createBatch';
    public static BATCH_DELETE = 'user/batch/deleteBatch';
    public static BATCH_GET_MY_PRIVATE_BATCHES = 'user/batch/getPrivateBatch';

    // CMS service
    // public static CMS_LIBRARY_QUESTION_GET_ALL = 'cms/question/fetchAll';
    public static CMS_LIBRARY_QUESTION_FETCH = 'cms/question/fetch';
    public static CMS_LIBRARY_QUESTION_CREATE = 'cms/question/create';
    public static CMS_LIBRARY_QUESTION_UPDATE = 'cms/question/update';
    public static CMS_LIBRARY_CKEDITOR_UPLOAD = 'cms/media/upload';
    public static CMS_LIBRARY_TEST_TEMPLATE_ADD = 'cms/test-template/add';
    public static CMS_LIBRARY_TEST_TEMPLATE_ALL = 'cms/test-template/all';
    public static CMS_LIBRARY_QUESTION_GET_BY_CONTENT_ID = (content_id) => `cms/question/getByContentId/${content_id}`;
    public static CMS_LIBRARY_TEST_PAPER_ALL = 'cms/test-paper/all';
    public static CMS_LIBRARY_TEST_PAPER_ADD = 'cms/test-paper/add';

    // Assessment service
    public static ASSESSMENT_TEST_PAPER_ALL = 'assessment/test-paper/fetchPapers';
    public static ASSESSMENT_TEST_PAPER_ADD = 'assessment/test-paper/add';
    public static ASSESSMENT_TEST_PAPER_DETETE = (testPaperId) => `assessment/test-paper/delete/${testPaperId}`;
    public static ASSESSMENT_EXAM_ADD = 'assessment/exam/add';
    public static ASSESSMENT_EXAM_DELETE = (examId) => `assessment/exam/delete/${examId}`;
    public static ASSESSMENT_EXAM_ALL = 'assessment/exam/all';

    // User Auth JWT
    public static AUTH_JWT_LOGIN = 'user/user/login';

    // Master Data
    public static CMS_MD_BOARDS = 'cms/board/all';
    public static CMS_MD_EXAMS = 'cms/exam/all';
    public static CMS_MD_STANDARDS = 'cms/standard/all';
    public static CMS_MD_SUBJECTS = 'cms/subject/all';
    public static CMS_MD_COURSES_ALL = 'cms/course/all';
    public static CMS_MD_COURSES_ADD = 'cms/course/add';
    public static CMS_MD_COURSE_TREE_ALL = 'cms/course-tree/all';
    public static CMS_MD_COURSE_TREE_ADD = 'cms/course-tree/add';
    public static CMS_MD_COURSE_TREE_UPDATE = 'cms/course-tree/update';
    public static CMS_MD_CHAPTER_ADD = 'cms/chapter/add';
    public static CMS_MD_CONCEPT_ADD = 'cms/concept/add';
    public static CMS_MD_COMPETITIVE_EXAM_ADD = 'cms/exam/add';

}