import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { UserEffects } from './user/user.effect';
import { UserReducer } from './user/user.reducer';
import { UserState } from './user/user.state';


export interface AppState {
    user: UserState
}

export const reducers: ActionReducerMap<AppState> = {
    user: UserReducer,
};

export const effects = [
    UserEffects,
];
