<!-- <div [class.hidden]="!(loaderService.loaderState | async)">
  <div class="loader-overlay">
    <div *ngIf="loaderService.loaderState | async" class="loader"></div>
  </div>
</div> -->

<div [class.hidden]="!(loaderService.loaderState | async)">
  <div class="loader-left">
    <div class="loader-overlay">
      <div *ngIf="loaderService.loaderState | async" class="loader"></div>
    </div>
  </div>

  <div class="loader-right">
    <div class="loader-overlay">
      <div *ngIf="loaderService.loaderState | async" class="loader"></div>
    </div>
  </div>
</div>
