import { createReducer, on, Action } from '@ngrx/store';
import * as UserActions from './user.action';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { UserState } from './user.state';



const initialState: UserState = {
    user: null,
}

const reducer = createReducer(
    initialState,

    on(UserActions.loadCurrentUserSuccess, (state, {user}) => {
        return { ...state, user: user };
    }),
)

export function UserReducer(state: UserState, action: Action) {
    return reducer(state, action);
}