import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class NonAuthGuardGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
  ) {
  }
  
  canActivate(): Observable<boolean> | boolean {
    return this.authService.isLoggedIn().pipe(map((loginStatus) => {
      // debugger
      if (loginStatus) {
        this.router.navigate(['/cms']);
        return false;
      } else {
        // if (!this.authService.getUserType() || !this.authService.getUsername() || this.authService.getUserType() == undefined) {
        //   this.authService.deleteUserData();
        //   this.router.navigate(['/login']);
        //   return false;
        // } else {
        //   return true;
        // }

        return true;
      }
    }));
  }
  
}
