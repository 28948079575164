import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  get(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
