import { Injectable } from '@angular/core';
import { toast } from '@samuelberthe/angular2-materialize';

@Injectable()
export class ToastService {

  private currentToast: any;

  constructor() { }

  public showToast(msg: string) {
    
    toast({html: msg});
  }
}
