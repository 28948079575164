import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router, CanActivate, CanDeactivate } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthGuardService implements CanActivate {

  
  constructor(
    private authService: AuthService, 
    private router: Router,
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    return this.authService.isLoggedIn().pipe(map((loginStatus) => {
      // debugger
      if (!loginStatus) {
        this.router.navigate(['/auth/login']);
        return false;
      } else {
        // if (!this.authService.getUserType() || !this.authService.getUsername() || this.authService.getUserType() == undefined) {
        //   this.authService.deleteUserData();
        //   this.router.navigate(['/login']);
        //   return false;
        // } else {
        //   return true;
        // }

        return true;
      }
    }));
  }

}
