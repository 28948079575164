import { AuthService } from'../services/auth/auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Api } from '../constants/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if(req.url.indexOf(Api.BASE_URL)===-1){
      return next.handle(req);
    }
    // Get the auth token from the service.
    // const authToken = this.auth.getAuthorizationToken();
    const accessToken = localStorage.getItem('accessToken');
    // let authToken = true
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (accessToken && accessToken !== 'undefined' && accessToken !== 'null') {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
      });
    // send cloned request with header to the next handler.
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}