import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from '../../constants/api';


@Injectable()
export class NetworkService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public get<T>(url: string, baseUrl?: string, params: any = {},  responseType?: any): Observable<T> {
    if (responseType) {
      return this.http.get<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, { params: {...params}, responseType: responseType as 'json' });
    }
    return this.http.get<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, { params: {...params} });

  }

  public post<T>(url: string, baseUrl?: string, data?: any): Observable<T> {
    return this.http.post<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, data);
  }

  public postWithHeaders<T>(url: string, baseUrl?: string, data?: any, options = {}): Observable<T> {
    return this.http.post<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, data, options);
  }

  public put<T>(url: string, baseUrl?: string, data?: any): Observable<T> {
    return this.http.put<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, data);
  }

  public delete<T>(url: string, baseUrl?: string): Observable<T> {
    return this.http.delete<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url);
  }

  public auth<T>(url: string, baseUrl?: string, data?: any): Observable<T> {
    return this.http.post<T>(baseUrl ? baseUrl + url : Api.BASE_URL + url, data);
  }

}
