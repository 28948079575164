import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import firebaseConfig from "../../shared/configurations/firebase.config" 
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from "@angular/fire/storage";
import { httpInterceptorProviders } from 'src/app/shared/http-interceptors';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { environment } from 'src/environments/environment';
import { ServicesModule } from 'src/app/shared/services/services.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, NavigationActionTiming} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from 'src/app/shared/store';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig), // Main Angular fire module 
    AngularFireStorageModule,  // Firebase database module

    ServicesModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    // StoreRouterConnectingModule.forRoot({serializer: CustomSerializer, navigationActionTiming: NavigationActionTiming.PostActivation}),

  ],
  providers: [
    httpInterceptorProviders,
    LoaderService

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
