import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth/auth/auth.service';
import { AuthGuardService } from './auth/auth-guard/auth-guard.service';
// import { FileDownloadService } from './file-download/file-download.service';
// import { LoggerService } from './logger/logger.service';
import { StorageService } from './storage/storage.service';
import { ToastService } from './toast/toast.service';
import { UserService } from './user/user.service';
import { MasterDataService } from './master-data/master-data.service';
import { BbbService } from './bbb/bbb.service';
import { NetworkService } from './network/network.service';
import { HttpClientModule } from '@angular/common/http';
import { NonAuthGuardGuard } from './auth/non-auth-guard/non-auth-guard.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class ServicesModule {

  constructor(@Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error(
        'ServicesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        AuthService,
        AuthGuardService,
        NonAuthGuardGuard,
        StorageService,
        ToastService,
        UserService,
        MasterDataService,
        NetworkService,
        BbbService
      ]
    };
  }
}
