import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from "src/app/shared/services/auth/auth-guard/auth-guard.service";
import { NonAuthGuardGuard } from "src/app/shared/services/auth/non-auth-guard/non-auth-guard.guard";
import { dashboardStudent, dashboardTeacher } from 'src/app/shared/utils/user-type.matcher';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [NonAuthGuardGuard],
    loadChildren: () => import('../landing/home/home.module').then(mod => mod.HomeModule)

  },
  {
    path: 'auth',
    canActivate: [NonAuthGuardGuard],
    loadChildren: () => import("../auth/auth.module").then(mod => mod.AuthModule)
  },
  {
    path: 'cms',
    canActivate: [AuthGuardService],
    loadChildren: () => import("../cms/cms.module").then(mod => mod.CmsModule)
  },
  {
    path: 'test-engine',
    canActivate: [AuthGuardService],
    loadChildren: () => import("../test-engine/test-engine.module").then(mod => mod.TestEngineModule)
  },
  {
    path: '**',
    redirectTo: 'cms'
  },
  {
    matcher: dashboardStudent,
    loadChildren: () => import("../student/dashboard/dashboard.module").then(mod => mod.DashboardModule)
  },
  {
    matcher: dashboardTeacher,
    loadChildren: () => import("../teacher/dashboard/dashboard.module").then(mod => mod.DashboardModule)
  },

  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
