import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Api } from '../constants/api';
// import { LoggerService } from '../services/logger/logger.service';
import { tap, map, catchError, retry } from 'rxjs/operators';
// import { BaseResponse } from '../dtos/response/base-response';
import { throwError } from 'rxjs';
import { BaseResponse } from '../dtos/response/base-response';
// import { AuthResponse } from '../dtos/response/auth-response';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    private domainOverrides: string[] = [
        "s3.ap-south-1.amazonaws.com", 
        "maps.googleapis.com",
        "api.razorpay.com",
    ];

    private errorModifierPaths: string[] = [
        "student/batch-student-map/save"
    ]

    constructor(
        // private logger: LoggerService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                    // 1. Response interceptor overrides
                    if (this.isDomainOverriden(event.url)) return event;

                    // 2. Error modifier paths -> means errors will not be passsed into error interceptor
                    //    We will be modifying the error in someething else
                    // if (this.isErrorModifierPaths(event.url)) {
                        
                    //     return new HttpResponse({
                    //         status: 200,
                    //         statusText: 'Modified error',
                    //         body: event.body
                    //     })
                    // }
                    // 3. Response interceptors mandarory for below
                    if (this.isVaildResponse(event.body, new BaseResponse<any>())) {
                        if (event.body.status !== 'success') {
                            // Put your toast message in here
                        }
                        return event.clone({ body: event.body.data });
                    } 
                    // else if (this.isVaildResponse(event.body, new AuthResponse())) {
                    //     return event;
                    // } 
                    
                    else {
                        throw new HttpErrorResponse({
                            error: 'Some error occured! Unprocessed entity!',
                            status: 422,
                            statusText: 'Unprocessed entity!'
                        });
                    }
                }
            })
        );
    }

    private isVaildResponse(response, responseReqd: BaseResponse<any>) {
        const mandatoryKeys = Object.keys(responseReqd.getInterfaceSkeleton());
        const responseKeys = Object.keys(response);
        for (const key of responseKeys) {
            if (mandatoryKeys.indexOf(key) === -1) {
                return false;
            }
        }
        return true;
    }

    private isDomainOverriden(url): boolean {
        for (let i=0; i<this.domainOverrides.length; i++) {
            if (url.split(this.domainOverrides[i]).length > 1) return true;
        }
        return false
    }

    private isErrorModifierPaths(url): boolean {
        for (let i=0; i<this.errorModifierPaths.length; i++) {
            if (url.split(this.errorModifierPaths[i]).length > 1) return true;
        }
        return false
    }
}
