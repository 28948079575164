import { Injectable } from '@angular/core';
import * as UserActions from './user.action';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { withLatestFrom, filter, switchMap, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '..';
import { of } from 'rxjs';
import { userSelectorCurrentUser } from './user.selector';


@Injectable()
export class UserEffects {
    updateCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateCurrentUser),
            withLatestFrom(this.store.pipe(select(userSelectorCurrentUser))),
            switchMap(([action, user]) => of(UserActions.loadCurrentUserSuccess({user: action.user})))
            
        )
    );

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
    ) { }

}