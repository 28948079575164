// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCxZQzTQg_Nselj_gi6eFSWlltT0r22Euo",
    authDomain: "xarothi-3010a.firebaseapp.com",
    databaseURL: "https://xarothi-3010a.firebaseio.com",
    projectId: "xarothi-3010a",
    storageBucket: "xarothi-3010a.appspot.com",
    messagingSenderId: "576056012448",
    appId: "1:576056012448:web:d445cf37180b270ba1a3c5",
    measurementId: "G-FM4YB7H05Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
